import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import ProductZoomer from 'vue-product-zoomer'

const axios= require('axios').default

//global configuration for axios ----- HOMOLOGATION --------
axios.defaults.baseURL="https://autolisting-ailemservices.comolapapaya.com/";

//global configuration for axios ----- PRODUCTION --------
// axios.defaults.baseURL="https://auto-listing.ailemservices.com/";

Vue.use(ProductZoomer)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
