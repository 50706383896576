<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="border-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-3">
              <img class="img-fluid" src="../../assets/images/import/logo2.png" alt="">
              <div class="d-flex pt-5">
                <div class="social-icons bg-info text-white">
                  <a href="">
                  <span class="mdi mdi-facebook"></span>
                  </a>
                </div>
                <div class="social-icons bg-secondary text-white" >
                  <a href="">
                  <span class="mdi mdi-instagram"></span>
                  </a>
                </div>
                <!-- <div class="social-icons bg-danger text-white"><span class="mdi mdi-dribbble"></span></div>
                <div class="social-icons bg-info text-white"><span class="mdi mdi-twitter"></span></div> -->
              </div>
            </div>
            <div class="col-lg-2">
              <div class="d-flex">
                <h3 class="text-white">Plan du site</h3>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="/">
                    Accueil
                  </router-link>
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="">
                    Véhicules
                  </router-link>
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="">
                  Services
                </router-link>
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">La société</h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">Contact</h6>
              </div>
            </div>
            <!-- 2ème rangée -->
            <div class="col-lg-3">
              <h3 class="text-white">Liens utiles</h3>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="">
                    Conditions généales de ventes
                  </router-link>
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                  <router-link to="/panier">
                    Mentions légales
                  </router-link>
                </h6>
              </div>
            </div>
            <!-- 2ème rangée -->
            <div class="col-lg-2">
              <h3 class="text-white">Horaires</h3>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                    <span class="green">Lundi :</span>  14h à 18h30
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                   <span class="green">Mardi - Vendredi :</span>  8h00 à 12h30 14h à 18h30
                </h6>
              </div>
              <div class="d-flex">
                <h6 class="font-weight-normal">
                   <span class="green">Samedi :</span>  Sur rendez-vous
                </h6>
              </div>
            </div>
            <div class="col-lg-2">
              <h6 class="text-center">
              Z.A du Fromental 43200 Yssingeaux <br> <span class="green">71 56 09 41</span>  <br> contact@auto-listing.fr
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2021 <a href="https://ailem.fr" target="_blank">ail'em</a>. All rights reserved.</span>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i></span>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>

<style scoped>
.footer{
  background: #292929;
}
.footer a {
  color: #fff;
}
</style>