import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'


Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '/',
  routes: [
    // {
    //   path: '/',
    //   redirect: '/vehicules',
    // },
    {
      path: '/vehicules',
      component: layout,
      children: [
        {
          path: '',
          name: 'vehicules',
          component: () => import('@/pages/vehicules')
        },
        {
          path: ':reference',
          name: 'vehiculeDetails',
          component: () => import('@/pages/vehicule-details')
        }
      ]
    }
    // {
    //   path: '*',
    //   redirect: '/error-404',
    //   component: {
    //     render (c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: 'error-404',
    //       component: () => import('@/pages/samples/error-pages/error-404')
    //     }
    //   ]
    // }
  ]
})
